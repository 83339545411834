<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">调拨单基本信息</el-col>
    </el-row>
    <el-form ref="form" :model="form" label-width="120px">
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="调拨单号">
            <el-input v-model="form.transferOrderCode" type="text" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="关联单号">
            <el-input v-model="form.relationCode" type="text" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :class="$i18n.locale" label="单据类型">
            <el-select v-model="form.orderTypeDict" style="width: 80%" disabled>
              <el-option
                v-for="item in _getAllCommodityDict('SCM_TRANSFER_ORDER_TYPE')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="调拨类型">
            <el-select v-model="form.transferTypeI18" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <el-select v-model="form.stateDict" style="width: 80%" disabled>
              <el-option
                v-for="item in _getAllCommodityDict('SCM_TRANSFER_STATE')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调出仓库">
            <el-select v-model="form.outWarehouseName" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="调入仓库">
            <el-select v-model="form.inWarehouseName" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="期望上架日期" :class="$i18n.locale">
            <el-date-picker v-model="form.expectShelfDate" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调出备货渠道">
            <el-select v-model="form.outStockChannelName" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="8">
          <el-form-item label="调入备货渠道">
            <el-select v-model="form.inStockChannelName" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="text" style="width: 80%" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :span="24">
        <el-col :span="24">
          <el-form-item label="异常记录">
            <el-input
              v-model="formattedExceptionRecord"
              type="textarea"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20">
        <span style="padding-left:25px">调拨明细</span>
      </el-col>
    </el-row>
    <vxe-table
      ref="uploadTableDataRef"
      border
      :data="outboundDetailTable"
    >
      <vxe-column type="seq" title="序号" width="60" />
      <vxe-column field="categoryName1" title="一级类别" />
      <vxe-column field="specificationModel" title="规格型号" />
      <vxe-column field="styleName" title="Style" />
      <vxe-column field="color" title="Color" />
      <vxe-column field="size" title="Size" />
      <vxe-column field="sku" title="Sku" />
      <vxe-column field="platSku" title="PlatSKu" />
      <vxe-column field="fnskuUpc" title="Fnsku/UPC">
        <template #default="{row}">
          <span>{{ row.fnskuUpc }}</span>
        </template>
      </vxe-column>
      <vxe-column field="position" title="定位" />
      <vxe-column field="negativeCashFlag" title="是否负现金流" />
      <vxe-column field="expectTransferNumber" title="预计调拨数量" />
      <vxe-column field="transferWarehouseNumber" title="已转仓库作业数量" />
      <vxe-column field="notOutLockQty" title="未发锁定数量" />
      <vxe-column field="shortageNumber" title="缺量数量" />
      <vxe-column field="outWarehouseNumber" title="已出库数量" />
      <vxe-column field="shelfNumber" title="已上架数量" />
    </vxe-table>

    <el-row class="grid-content bg-blue" type="flex" justify="center" style="margin-top: 20px;">
      <!-- <el-button @click="cancle()">{{ $t('title.cancel') }}</el-button> -->
      <el-button type="primary" @click="goBack">返回</el-button>
    </el-row>
  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getPhysicsWarehouseList, StockChannelList, calcTransferType, TransferInfo } from '@/api/scm-api'

export default {
  mixins: [commodityInfoDict],
  data() {
    return {
      loading: false,
      editVisible: false,
      dialogVisible: false,
      fordisabled: false, // 禁用文本框
      isDisabled: false,
      importdata: [],
      importdialog: false,
      importDetaildialog: false,
      file: '',
      fileList: [],
      importDialog: false,
      uploadTableVisible: false,
      editForm: {
        effectiveStartDate: '',
        effectiveEndDate: '',
        isExcludingTax: 1
      },
      sidvoList: [],
      ids: [],
      vendorOptions: [],
      employeeOptions: [],
      styleOptions: [],
      editRow: {},
      showRow: false, // 切换显示input
      releaseTaskDialog: false, // 释放任务
      Loadingsave: false,
      Loadingcommit: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      row: '',
      tabClickIndex: '',
      tabClickLabel: '',
      vendorId: '',
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },

      warehouseOption: [],
      optionsWarehouseConfig: { key: 'id', label: 'warehouseName', value: 'logicWarehouseCode' },
      StockChannelOptions: [],
      StockChannelOptions1: [],
      StockChannelOptions2: [],
      form: {
        transferTypeDict: '',
        respectTime: '',
        outWarehouseCode: '',
        inWarehouseCode: '',
        outStockChannelId: '',
        inStockChannelId: '',
        exceptionRecord: []
      },
      status: 0,
      outboundDetailTable: []

    }
  },
  computed: {
    isIframe() {
      return this.$route.path.includes('/iframe/')
    },
    formattedExceptionRecord() {
      return this.form.exceptionRecord ? this.form.exceptionRecord.join('\n') : ''
    },
    isTransported() {
      return this.form.offerStatus === '草稿' || this.form.offerStatus === '审批中'
    },
    user() {
      return this.$store.getters.authorities
    },
    isInsert() {
      if (this.form.transferTypeDict && this.form.outWarehouseCode && this.form.outStockChannelId && this.form.inWarehouseCode && this.form.inStockChannelId) {
        if (this.status === 1 || (this.status !== 1 && this.form.respectTime)) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    isNull() {
      if (this.outboundDetailTable) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    'editVisible'(val) {
      if (!val) {
        this.editForm = this.$options.data.call(this).editForm
        this.$refs['editForm'].resetFields()
      }
    }
  },
  mounted() {
    this._queryWarehouseList()
    this._StockChannelList()
    this.queryClick()
  },
  destroyed() {
  },
  methods: {
    async queryClick() {
      const detailParam = this.$route.query.transferOrderCode
      const { datas } = await TransferInfo(detailParam)
      this.form = datas
      this.outboundDetailTable = datas.detailList
      // this.outboundDetailTable = await TransferInfo(detailParam)
    },
    // 下拉数据获取
    async _queryWarehouseList() {
      const { datas } = await getPhysicsWarehouseList({ status: 1 })
      this.warehouseOption = datas
    },
    async _StockChannelList() {
      const { datas } = await StockChannelList({ status: 1 })
      this.StockChannelOptions = datas
      this.StockChannelOptions1 = this.StockChannelOptions
      this.StockChannelOptions2 = this.StockChannelOptions
    },
    // 获取相关调拨类型
    async getWarehouse() {
      if (this.form.inWarehouseCode && this.form.outWarehouseCode) {
        const inWarehouseAreaType = this.warehouseOption.find(item => item.id === this.form.inWarehouseCode).areaType
        const outWarehouseAreaType = this.warehouseOption.find(item => item.id === this.form.outWarehouseCode).areaType
        const getParams = Object.assign({}, { inWarehouseAreaType, outWarehouseAreaType }, { inWarehouseCode: this.form.inWarehouseCode, outWarehouseCode: this.form.outWarehouseCode })
        const { code, datas } = await calcTransferType(getParams)
        if (code === 0) {
          this.form.transferTypeDict = parseInt(datas)
          if (!datas) {
            this.$message({
              message: '不支持的调拨类型',
              type: 'warning'
            })
            this.form.transferTypeDict = ''
          }
          if (datas === '1') {
            this.status = 1
          } else {
            this.status = 0
            this.form.inStockChannelId = this.form.outStockChannelId
          }
        }
      }
    },
    // 调入备货渠道的值
    getOutStock() {
      if (this.status === 1) {
        if (this.form.outStockChannelId && !this.form.inStockChannelId) {
          this.StockChannelOptions2 = this.StockChannelOptions.filter(item => item.channelId !== this.form.outStockChannelId)
        }
        if (this.form.inStockChannelId && !this.form.outStockChannelId) {
          this.StockChannelOptions1 = this.StockChannelOptions.filter(item => item.channelId !== this.form.inStockChannelId)
        }
        if (!this.form.inStockChannelId && !this.form.outStockChannelId) {
          this.StockChannelOptions1 = this.StockChannelOptions
          this.StockChannelOptions2 = this.StockChannelOptions
        }
      } else {
        this.form.inStockChannelId = this.form.outStockChannelId
      }
    },
    goBack() {
      this.$router.push({
        name: this.isIframe ? 'IframeTransferOrderManagement' : 'TransferOrderManagement'
      })
      window.addEventListener('message', e => {
        if (e.data) {
          console.log('e.data: ', e.data)
          this.permissonIframe = e.data.query?.meta?.permissions || []
        }
      })
    }

  }
}
</script>
<style lang="scss" scope>
.warning-row {
  color: red !important;
}
</style>

